<template>
  <b-card-code>
    <b-form
      id="addHomologacionBank"
      @submit.prevent="addHomolgacionBank()"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Banco Origen"
            label-for="bankOrigin"
          >
            <v-select
              id="bankOrigin"
              v-model="item.sourceBank"
              label="title"
              :options="sourceBank"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Gerencia Origen"
            label-for="gerencia"
          >
            <v-select
              id="gerencia"
              v-model="item.sourceManagement"
              label="title"
              :options="sourceManagement"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Código del Centro de Costo Origen"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="item.sourceCenter"
              type="number"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Cuenta Contable Origen"
            label-for="accountOrigin"
          >
            <b-form-input
              id="accountOrigin"
              v-model="item.sourceAccount"
              type="number"
              min="1000000000000000"
              max="9999999999999999"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Banco Destino"
            label-for="bankDestiny"
          >
            <v-select
              id="bankDestiny"
              v-model="item.targetBank"
              label="title"
              :options="targetBank"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Gerencia Destino"
            label-for="ManagementDest"
          >
            <v-select
              id="ManagementDest"
              v-model="selectedManagementDest"
              label="gerencia"
              :options="gerenciasDirectivas"
              @input="selectedCostCenters=null;item.targetAccount=null"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Centro de Costos"
            label-for="costCenters"
          >
            <v-select
              id="costCenters"
              v-model="selectedCostCenters"
              label="label"
              :options="filteredCC"
              :reduce="option => option.idCentro"
              @input="selectedCenter => {filterAccounts(selectedCenter);item.targetAccount=null}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Cuenta Contable"
            label-for="cuentaContable"
          >
            <v-select
              id="cuentaContable"
              v-model="item.targetAccount"
              label="cuenta"
              :options="accountsFiltered"
              :reduce="option => option.idCuenta"
            />
          </b-form-group>

        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Estatus"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="item.status"
              label="status"
              :options="statuses"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            to="inter-bank-homologation"
          >
            Cancelar
          </b-button>
        </b-col>
        <p>{{ errorMessage }}</p>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      accounts: [],
      accountsFiltered: [],
      item: {targetAccount: null},
      selectedManagementDest: [],
      selectedCostCenters: [],
      filteredCC: [],
      loading: false,
      errorMessage: '',
      sourceBank: [{ title: 'Banco 02', value:'2' }],
      sourceManagement: [{ title: 'Gerencia Externa', value:'777' }],
      targetBank: [{ title: 'Banco 1', value:'1' }],
      targetManagement: [{ title: 'Gerencia Directiva - Banco 2', value:'888' }],
      costCenters: [],
      gerenciasDirectivas: [],
      statuses: [],
    }
  },
  computed: {
  },
  watch:{
    selectedManagementDest(val){
      console.log('selectedManagementDest: ', val.idGerencia)
      this.filteredCostCenters(val)
      this.selectedCostCenters = ''
    },
    selectedCostCenters(val){
      console.log('selectedCostCenters a: ', val.idGerencia)
      // this.clearCuentaContable()
    },

  },
  created() {
    this.catalogLoad()
  },
  methods: {
    async catalogLoad(){
      const dataAccounts = await this.$http.get(`${environment.uri}/accounts/allx`)
      this.accounts = dataAccounts.data
      const costCenters= await this.$http.get(`${environment.uri}/ccenters/all`)
      this.costCenters = costCenters.data
      const dataGerencia = await this.$http.get(`${environment.uri}/mngmts/all`)
      this.gerenciasDirectivas = dataGerencia.data
      this.statuses = this.$store.getters['catalog/allStatuses']
    },

    filteredCostCenters(e){
      this.filteredCC = this.costCenters.filter(field => field.idGerencia === e.idGerencia)
    },

    filterAccounts(selectedCenter) {
      this.accountsFiltered = this.accounts.filter(acc => acc.idCentro === selectedCenter)
    },

    clearCuentaContable(){
      console.log('clear')
    },

    async addHomolgacionBank(){
        this.loading = true
        this.errorMessage = ''
        this.$http.post(`${environment.uri}/interbank/create`,
            {
              "sourceBank": this.item.sourceBank.value,
              "sourceManagement": this.item.sourceManagement.value,
              "sourceCenter": this.item.sourceCenter,
              "sourceAccount": this.item.sourceAccount,
              "targetBank": this.item.targetBank.value,
              "targetManagement": this.selectedManagementDest.idGerencia,
              "targetCenter": this.selectedCostCenters,
              "targetAccount": this.item.targetAccount,
              "status": this.item.status.idStatus
            })
            .then(() => {
              this.loading = false
              this.$router.replace('/cda/admin/inter-bank-homologation')
            })
            .catch(error => {
              console.error(error)
              this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
              this.loading = false
            })
    }

  },
}
</script>

<style scoped>

</style>
